<template>
    <div class="col-md-6" style="margin: auto; padding: 8em 0">
        <div>
            <h4 style="padding: 2em">
                Welcome, please enter an email and password
            </h4>
            <Form @submit="handleRegister" :validation-schema="schema">
                <div v-if="!successful">
                    <div class="form-group">
                        <label for="code">Registration Code</label>
                        <Field
                            name="code"
                            type="text"
                            class="form-control"
                        />
                        <ErrorMessage name="code" class="error-feedback" />
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <Field name="email" type="email" class="form-control" />
                        <ErrorMessage name="email" class="error-feedback" />
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <Field
                            name="password"
                            type="password"
                            class="form-control"
                        />
                        <ErrorMessage name="password" class="error-feedback" />
                    </div>
                    <div class="form-group">
                        <button
                            class="btn btn-primary btn-block"
                            :disabled="loading"
                        >
                            <span
                                v-show="loading"
                                class="spinner-border spinner-border-sm"
                            ></span>
                            Sign Up
                        </button>
                    </div>
                </div>
            </Form>
            <div
                v-if="message"
                class="alert"
                :class="successful ? 'alert-success' : 'alert-danger'"
            >
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
    name: 'Register',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            // username: yup
            //     .string()
            //     .required('Username is required!')
            //     .min(3, 'Must be at least 3 characters!')
            //     .max(20, 'Must be maximum 20 characters!'),
            code: yup
                .string()
                .required('Registration code is required!'),
                // .min(3, 'Must be at least 3 characters!')
                // .max(20, 'Must be maximum 20 characters!'),
            email: yup
                .string()
                .required('Email is required!')
                .email('Email is invalid!')
                .max(50, 'Must be maximum 50 characters!'),
            password: yup
                .string()
                .required('Password is required!')
                .min(6, 'Must be at least 6 characters!')
                .max(40, 'Must be maximum 40 characters!'),
        })
        return {
            successful: false,
            loading: false,
            message: '',
            schema,
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        },
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push('/profile')
        }
    },
    methods: {
        handleRegister(user) {
            this.message = ''
            this.successful = false
            this.loading = true
            this.$store.dispatch('auth/register', user).then(
                (data) => {
                    console.log("store dispath", data)
                    this.message = data.message
                    this.successful = true
                    this.loading = false
                    this.$router.push('/')
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                    this.successful = false
                    this.loading = false
                }
            )
            
        },
    },
}
</script>

<style>
.register-form {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1em;
}

.button {
    width: 100%;
}
</style>
