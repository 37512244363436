<template>
    <header class="jumbotron">
        <h3>{{ content }}</h3>
        <div>
            <button @click="login">Log in</button>
        </div>
    </header>
</template>
<script>
// import { useAuth0 } from '@auth0/auth0-vue'
import UserService from '../services/user.service'

export default {
    name: 'Home',
    data() {
        return {
            content: '',
        }
    },
    mounted() {
        UserService.getPublicContent().then(
            (response) => {
                this.content = response.data
            },
            (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
            }
        )
    },
    // setup() {
    //   const test = useAuth0()
    //   console.log(test)

    //     return {
    //         login: () => {
    //             // loginWithRedirect()
    //         },
    //     }
    // },
}
</script>
