import axios from 'axios'
const url = `${process.env.VUE_APP_API_URL}/api/auth/`

class AuthService {
    login(user) {
        return axios
            .post(url + 'login', {
                username: user.username,
                password: user.password,
            })
            .then((response) => {
                console.log(response)
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data))
                }
                return response.data
            })
    }
    logout() {
        localStorage.removeItem('user')
    }
    register(user) {
        return axios.post(url + 'register', {
            code: user.code,
            username: user.username,
            email: user.email,
            password: user.password,
        })
    }
}

export default new AuthService()
