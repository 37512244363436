import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import config from '../auth_config.json'
import store from './store'
import { createAuth0 } from '@auth0/auth0-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from './plugins/font-awesome'
import Toaster from "@meforma/vue-toaster"


const auth0PluginOptions = {
    domain: config.domain,
    client_id: config.clientId,
    redirect_uri: window.location.origin,
}

createApp(App)
    .use(router)
    .use(createAuth0(auth0PluginOptions))
    .use(store)
    .use(Toaster)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
