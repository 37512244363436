import { createRouter, createWebHistory } from 'vue-router'
import Home from "../components/Home.vue";
import Login from "../components/Login.vue";
import Register from "../components/Register.vue";
const Profile = () => import("../components/Profile.vue")

const routes = [
    {
        path: '/',
        component: () => import('../components/Resume.vue'),
    },
    {
        path: '/bets',
        name: 'bets',
        component: () => import('../components/Bets.vue'),
    },
    {
        path: '/journal',
        component: () => import('../components/JournalEntry.vue'),
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/register",
        component: Register,
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router